<template>
    <main class="share-step-one">
        <ShWidgetStepper model="normal" :step="1" class="mt-6" />
        <section class="share-step-one__section">
            <form class="form" @submit.prevent>
                <MiscTabs :info="sharetabs" class="mb-3" />
                <h1>{{ $lang.components.cardShareStepOne.share }} {{ type.wordSingular }}</h1>
                <label> {{ $lang.components.cardShareStepOne.paste_link }} </label>
                <div class="form__fields">
                    <div
                        class="offer"
                        :class="type.variant === 'offer' ? 'sm:w-full' : 'sm:w-2/3'"
                        @click="verification.url = false"
                    >
                        <InputGeneric
                            v-model.trim="form.url"
                            :image="$assets.gray.linkSoft"
                            imagestyle="top-3 h-4"
                            bgcolor="light"
                            :placeholder="`https://www.site.com/${kind}...`"
                            :invalid="verification.url"
                        >
                            {{ verification.url }}
                        </InputGeneric>
                    </div>
                    <div v-if="type.variant === 'coupon'" class="coupon">
                        <InputGeneric
                            v-model="form.code"
                            :placeholder="$lang.components.cardShareStepOne.coupon_code"
                            :image="$assets.gray.cuponSoft"
                            imagestyle="top-3 h-4"
                            bgcolor="light"
                        >
                        </InputGeneric>
                    </div>
                </div>
                <template v-if="!isShareDuplicated">
                    <div class="form__action-buttons">
                        <button type="button" class="link-not-needed" @click="handleNext(true)">
                            {{ $lang.components.cardShareStepOne.no_link_needed }}
                        </button>
                        <button
                            class="start"
                            :class="loading ? 'bg-gray-200' : 'bg-site-primary'"
                            :disabled="loading"
                            type="submit"
                            @click="handleNext()"
                        >
                            <img
                                v-show="loading"
                                :src="$assets.primary.loading"
                                height="17"
                                width="17"
                                alt="icon"
                                class="spin"
                            />
                            <span v-show="!loading">{{ $lang.components.cardShareStepOne.start }}</span>
                        </button>
                    </div>
                </template>
            </form>
        </section>
    </main>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()
const { $lang, $assets } = useNuxtApp()

const props = defineProps({
    kind: {
        type: String as PropType<'offer' | 'coupon'>,
        required: true,
    },
})

const type = computed(() => variants[props.kind])

const verification = reactive({
    url: false as boolean | string,
})
const form = reactive({
    url: '',
    code: '',
})
const isShareDuplicated = ref(false)
const loading = ref(false)
const sharetabs = reactive({
    show: 0,
    tabs: [
        {
            title: $lang.components.cardShareStepOne.share_offer,
            to: `/${$lang.routes.share}/${$lang.routes.offer}`,
            icon: {
                active: $assets.primary.offer,
                inactive: $assets.gray.offer,
            },
        },
        {
            title: $lang.components.cardShareStepOne.share_coupon,
            to: `/${$lang.routes.share}/${$lang.routes.coupon}`,
            icon: {
                active: $assets.primary.coupons,
                inactive: $assets.gray.cuponSoft,
            },
        },
    ],
})

watch(
    type,
    (newValue) => {
        if (newValue.variant === 'coupon') {
            sharetabs.show = 1
        } else {
            sharetabs.show = 0
        }
    },
    { immediate: true },
)
const AuthStore = useAuthStore()
const Router = useRouter()
const RootStore = useRootStore()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const handleNext = async (pass?: boolean) => {
    loading.value = true

    const path = `${$lang.routes.share}-${type.value.wordPlain}-${$lang.routes.create}`

    if (pass) {
        return Router.push({
            name: path,
        })
    }

    if (form.url.length === 0) {
        verification.url = $lang.components.cardShareStepOne.insert_valid_url
        loading.value = false
        return
    }

    const res = await $fetch<Api.Responses.General.UseScrapper>(endpoints.general.scrapper.path, {
        headers: buildHeaders(AuthStore.SessionToken),
        baseURL,
        params: { url: form.url, type: type.value.variant },
        method: 'GET',
    }).catch((e) => e.data)

    if (res.feedback === 'params_validation_failed') {
        verification.url = res.data.url?.[0]
    } else if (res.feedback === 'data_success' || res.feedback === 'data_empty') {
        if (res.feedback === 'data_empty') {
            RootStore.setSiteNotification({
                duration: 3000,
                dismissButtonText: $lang.components.cardShareStepOne.hide,
                text: $lang.components.cardShareStepOne.image_not_found,
                type: 'help',
            })
            return Router.push({
                name: path,
                query: {
                    url: form.url,
                    code: form.code,
                },
            })
        }
        return Router.push({
            name: path,
            query: {
                url: form.url,
                code: form.code,
                images: res.data.images.toString(),
            },
        })
    } else {
        RootStore.setSiteNotification({
            duration: 3000,
            dismissButtonText: $lang.components.cardShareStepOne.hide,
            text: $lang.components.cardShareStepOne.error,
            type: 'error',
        })
    }
    loading.value = false
}
const handleCancel = () => {
    isShareDuplicated.value = false
    form.url = form.code = ''
}
</script>

<style lang="postcss" scoped>
.share-step-one {
    @apply mx-auto mb-10;
    &__section {
        @apply mx-auto mt-10 max-w-3xl justify-center rounded-xl bg-white p-3;
        .form {
            @apply space-y-4;
            h1 {
                @apply text-3xl font-semibold text-gray-800;
            }
            label {
                @apply text-xl text-gray-800;
            }
            &__fields {
                @apply flex w-full flex-col sm:flex-row sm:gap-2;
                .offer {
                    @apply w-full;
                }
                .coupon {
                    @apply w-full sm:w-1/3;
                }
            }
            &__action-buttons {
                @apply flex items-center justify-end gap-5;
                .link-not-needed {
                    @apply font-medium text-gray-800 hover:underline;
                }
                .start {
                    @apply rounded-xl px-10 py-3 font-medium text-white;
                }
            }
            &__not-found {
                @apply flex w-full flex-col items-start space-y-3 rounded-xl border p-9 py-3 shadow-lg;
                h1 {
                    @apply text-base font-semibold text-red-500;
                }
                h2 {
                    @apply text-sm;
                }
                .item-and-buttons {
                    @apply block w-full justify-between md:flex;
                    &__item {
                        @apply mx-auto flex w-full gap-2 rounded-2xl border border-opacity-5 bg-white p-2 hover:border-gray-300 sm:w-2/3 md:mx-px md:w-1/2;
                        img {
                            @apply h-16 w-14;
                        }
                        &__content {
                            @apply text-gray-800;
                            h2 {
                                @apply font-medium text-gray-800;
                            }
                            span {
                                @apply text-sm font-medium text-site-primary;
                            }
                            &__stats {
                                @apply flex items-center gap-3;
                                .price {
                                    @apply font-semibold;
                                }
                                .time {
                                    @apply text-sm text-gray-800;
                                }
                            }
                        }
                    }
                    &__buttons {
                        @apply mt-4 flex items-center justify-center gap-3 md:mt-0;
                        .new {
                            @apply font-medium text-gray-800 underline underline-offset-4;
                        }
                        .cancel {
                            @apply rounded-xl bg-site-primary px-10 py-3 font-medium text-white;
                        }
                    }
                }
            }
        }
    }
}
</style>
